import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../storages/auth";
import LayoutsTopbar from "./Topbar";
import Container from "./Container";
import LayoutsFooter from "./Footer";
import { fetchPengumuman } from "../../api";
import ModalPrimary from "../ModalPrimary";
import ModalWarning from "../ModalWarning";

const LayoutsMain = (props) => {
  const { children } = props;

  // const title = useRecoilValue(titleState);
  // const user = useRecoilValue(userState);
  const token = useRecoilValue(tokenState);

  const [itemsPem, setItemsPem] = useState([]);
  const [currentPem, setCurrentPem] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchPengumuman("all", token);
      // console.log(res);
      // console.log(res.data.pengumuman);
      setItemsPem(res.data.pengumuman);
      setCurrentPem(0);
      setShowModal(true); // Menampilkan modal pertama kali
    };

    fetchData();
  }, [token]);

  // const nextPengumuman = () => {
  //   // setCurrentPem(prevIndex => prevIndex + 1);
  //   // setShow(false);
  //   setShowStates(prevStates => prevStates.map((state, index) => index === currentPem ? false : state));
  //   setCurrentPem(prevIndex => prevIndex + 1);
  // }
  const nextPengumuman = () => {
    if (currentPem < itemsPem.length - 1) {
      setCurrentPem(prevIndex => prevIndex + 1);
      setShowModal(true);
    } else {
      setShowModal(false); // Menutup modal setelah data terakhir
    }
  };

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  const showPengumuman = itemsPem[currentPem];



  // const [show, setShow] = useState(true);

  //   const openModal = () => {
  //       setShow(true);
  //   }
  //   const closeModal = () => {
  //       setShow(false);
  //   }

  // const [modalMendadak, setModalMendadak] = useState(true);

  return (
    <>
      {showPengumuman && (
        currentPem % 2 === 0 ? (
          <ModalPrimary 
          show={showModal}
          onClose={nextPengumuman}
          judul={showPengumuman && showPengumuman.judul}
          deskripsi={showPengumuman && showPengumuman.isi}
          dari={showPengumuman && showPengumuman.dari.split('-').reverse().join('-')}
          sampai={showPengumuman && showPengumuman.sampai.split('-').reverse().join('-')}
          file={showPengumuman && showPengumuman.file}
        />
        ) : (
          <ModalWarning 
            show={showModal}
            onClose={nextPengumuman}
            judul={showPengumuman && showPengumuman.judul}
            deskripsi={showPengumuman && showPengumuman.isi}
            dari={showPengumuman && showPengumuman.dari.split('-').reverse().join('-')}
            sampai={showPengumuman && showPengumuman.sampai.split('-').reverse().join('-')}
            file={showPengumuman && showPengumuman.file}
          />
        )
      )}

      {/* <ModalPrimary
        show={modalMendadak}
        onClose={() => setModalMendadak(false)}
        judul="Perhatian"
      >
        <p className="mt-3 mb-0">Sebelum melanjutkan ke fitur berikutnya harap mengisi form survei terlebih dahulu melalui link berikut</p>
        <a className="d-inline-block pt-3" href="https://bit.ly/SurveiMahasiswaGanjil2025" target="_blank">{`https://bit.ly/SurveiMahasiswaGanjil2025`}</a>
      </ModalPrimary> */}
      <LayoutsTopbar />
      <Container>{children}</Container>
      <LayoutsFooter />
    </>
  );
};

export default LayoutsMain;
